.orders {
    height: fit-content;
    border-radius: 50px;
    padding: 4px 10px 4px 10px;
    text-transform: capitalize;
}
.order_status-packing {
    background-color: #c0f2ec;
}
.order_status-received {
    background-color: #ffe0c2;
}
.order_status-new {
    background-color: #c4edc6;
}
.order_status-assigned {
    background-color: #faedbf;
}
.order_status-completed {
    background-color: #cfe2fe;
}
.order_status-dispatched {
    background-color: #c0f2ec;
}
.order_status-cancelled {
    background-color: #dfe5ec;
    color: #1111117a;
}

span.vip-indicator::before,
span.is_ambassador-indicator::before {
    content: '';
    height: 6px;
    width: 6px;
    border-radius: 100%;
    position: absolute;
    left: 1px;
    top: 21px;
}
.vip-indicator::before {
    background: #ffb060;
}
.is_ambassador-indicator::before {
    background: #7aad37;
}
table tbody tr th,
table tbody tr td {
    padding: 12px 15px !important;
    align-items: center !important;
}

table tbody tr td img{
    display:inline-block !important
}
.MuiTablePagination-toolbar button svg {
    display: block !important;
} 
